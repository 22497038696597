<template>
  <div class="home" ref="home" id="home">
    <!-- section1 -->
    <div class="home_section_top bgc2">
      <div class="home_section_top_container space-between-row">
        <div class="col">
          <div class="home_section_top_title fc1">{{ this.$t("trace.banner.title") }}</div>
          <div class="home_section_top_subtitle fc2">{{ this.$t("trace.banner.subTitle") }}</div>
          <div class="home_section_top_btn pointer" @click="goCaseApply">
            {{ this.$t("trace.banner.contactUs") }}
          </div>
        </div>
        <div class="col">
          <img class="home_section_top_img" :src="require(`@/assets/img/prod-recovery.png`)"/>
        </div>
      </div>
    </div>

    <!-- section6 成果-->
    <div class="home_section home_section_achievements bc1">
      <div class="home_section_container space-between-row">
        <div class="col achievements-item">
          <div>
            <label class="title">{{ this.$t("home.section6.info1.title") }}</label>
            <label class="unit">{{ this.$t("home.section6.info1.unit") }}</label>
          </div>
          <div>
            <label class="subTitle">{{ this.$t("home.section6.info1.subTitle") }}</label>
          </div>
        </div>
        <div class="col achievements-item">
          <div>
            <label class="title">{{ this.$t("home.section6.info2.title") }}</label>
            <label class="unit">{{ this.$t("home.section6.info2.unit") }}</label>
          </div>
          <div>
            <label class="subTitle">{{ this.$t("home.section6.info2.subTitle") }}</label>
          </div>
        </div>
        <div class="col achievements-item">
          <div>
            <label class="title">{{ this.$t("home.section6.info3.title") }}</label>
            <label class="unit">{{ this.$t("home.section6.info3.unit") }}</label>
          </div>
          <div>
            <label class="subTitle">{{ this.$t("home.section6.info3.subTitle") }}</label>
          </div>
        </div>
        <div class="col achievements-item">
          <div>
            <label class="title">{{ this.$t("home.section6.info4.title") }}</label>
            <label class="unit">{{ this.$t("home.section6.info4.unit") }}</label>
          </div>
          <div>
            <label class="subTitle">{{ this.$t("home.section6.info4.subTitle") }}</label>
          </div>
        </div>
      </div>
    </div>

    <!-- 服务流程-->
    <div class="home_section home_section_service_flow bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("trace.services_flow.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_3 hover-bgc1 border1">
              <div class="home_section_3_no">01</div>
              <div class="col">
                <label class="home_section_title2 fc1">{{ this.$t("trace.services_flow.info1.title") }}</label>
                <label class="home_section_title2 fc2">{{ this.$t("trace.services_flow.info1.subTitle") }}</label>
              </div>
            </div>
            <div class="home_section_3 hover-bgc1 border1">
              <div class="home_section_3_no">02</div>
              <div class="col">
                <label class="home_section_title2 fc1">{{ this.$t("trace.services_flow.info2.title") }}</label>
                <label class="home_section_title2 fc2">{{ this.$t("trace.services_flow.info2.subTitle") }}</label>
              </div>
            </div>
            <div class="home_section_3 hover-bgc1 border1">
              <div class="home_section_3_no">03</div>
              <div class="col">
                <label class="home_section_title2 fc1">{{ this.$t("trace.services_flow.info3.title") }}</label>
                <label class="home_section_title2 fc2">{{ this.$t("trace.services_flow.info3.subTitle") }}</label>
              </div>
            </div>
            <div class="home_section_3 hover-bgc1 border1 position">
              <div class="bit-button fc1">
                Excellent
              </div>
              <div class="home_section_3_no">04</div>
              <div class="col">
                <label class="home_section_title2 fc1">{{ this.$t("trace.services_flow.info4.title") }}</label>
                <label class="home_section_title2 fc2">{{ this.$t("trace.services_flow.info4.subTitle") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 服务内容-->
    <div class="home_section home_section_service_content bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("trace.services_content.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/trace-service-content-1.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.services_content.info1.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.services_content.info1.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/trace-service-content-2.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.services_content.info2.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.services_content.info2.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/trace-service-content-3.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.services_content.info3.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.services_content.info3.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/trace-service-content-4.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.services_content.info4.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.services_content.info4.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/trace-service-content-5.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.services_content.info5.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.services_content.info5.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/trace-service-content-6.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.services_content.info6.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.services_content.info6.subTitle") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 核心优势-->
    <div class="home_section home_section_advantages bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("trace.advantages.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_3 hover-bgc1 border1 col">
              <img :src="require(`@/assets/img/trace-advantages-1.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.advantages.info1.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.advantages.info1.subTitle") }}</label>
            </div>
            <div class="home_section_3 hover-bgc1 border1 col">
              <img :src="require(`@/assets/img/trace-advantages-2.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.advantages.info2.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.advantages.info2.subTitle") }}</label>
            </div>
            <div class="home_section_3 hover-bgc1 border1 col">
              <img :src="require(`@/assets/img/trace-advantages-3.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("trace.advantages.info3.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("trace.advantages.info3.subTitle") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作支持-->
    <div class="home_section home_section_cooperation bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("trace.cooperation.title") }}</label>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_3 bgc3 hover-border hover-no-bg">
              <div class="home_section_title2 fc1" :class="lang=='en'?'w100':''">{{ this.$t("trace.cooperation.info1.title") }}</div>
              <div class="home_section_title3 fc2" :class="lang=='en'?'w100':''">{{ this.$t("trace.cooperation.info1.partner1") }}</div>
              <div class="home_section_title3 fc2" :class="lang=='en'?'w100':''">{{ this.$t("trace.cooperation.info1.partner2") }}</div>
              <div class="home_section_title3 fc2" :class="lang=='en'?'w100':''">{{ this.$t("trace.cooperation.info1.partner3") }}</div>
              <div class="home_section_title3 fc2" :class="lang=='en'?'w100':''">{{ this.$t("trace.cooperation.info1.partner4") }}</div>
            </div>
            <div class="home_section_3 bgc3 hover-border hover-no-bg">
              <div class="home_section_title2 fc1">{{ this.$t("trace.cooperation.info2.title") }}</div>
              <div class="home_section_title3 fc2">{{ this.$t("trace.cooperation.info2.partner1") }}</div>
              <div class="home_section_title3 fc2">{{ this.$t("trace.cooperation.info2.partner2") }}</div>
              <div class="home_section_title3 fc2">{{ this.$t("trace.cooperation.info2.partner3") }}</div>
              <div class="home_section_title3 fc2">{{ this.$t("trace.cooperation.info2.partner4") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section7 case-->
    <div class="home_section home_section_case bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section7.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub hover-jump hover-bgc1 border1 col" v-for="item in newsList" :key="item.url" @click="goBlank(item.url)">
              <div class="news_title fc1">{{ item.title }}</div>
              <div class="news_intro fc2">{{ item.detail }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section8 social-->
    <div class="home_section home_section_social bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section8.title") }}</label>
          </div>
          <div class="home_section_title2 space-between-row fc2" style="margin-top: 16px;">
            <label>{{ this.$t("home.section8.subTitle") }}</label>
          </div>
          <div class="row">
            <img class="hover-jump" @click="goBlank(x_url)" :src="require(`@/assets/img/icon-x.png`)" width="48" height="48"/>
            <img class="hover-jump" @click="goBlank(redbook_url)" :src="require(`@/assets/img/icon-redbook.png`)" width="48" height="48"/>
            <img class="hover-jump" @click="goBlank(tiktok_url)" :src="require(`@/assets/img/icon-tiktok.png`)" width="48" height="48"/>
            <el-popover
              :popper-class="'qrcode-popover shadow-'+theme"
              placement="bottom-start"
              width="240"
              :visible-arrow="false"
              trigger="click">
              <img slot="reference" class="hover-jump" :src="require(`@/assets/img/icon-wechat.png`)" width="48" height="48"/>
              <img class="code-item-img" :src="require(`@/assets/img/qrcode-weixin-${theme}.png`)" width="248" height="248"/>
            </el-popover>
          </div>
        </div>
        <div>
          <img :src="require(`@/assets/img/icon-news.png`)" width="380" height="230"/>
        </div>
      </div>
    </div>

    <BitBottom/>

  </div>
</template>

<script>
import { newsList } from "@/api/case";
import BitBottom from "@/components/BitBottom.vue";

export default {
  name: "home",
  components: { BitBottom },
  mounted() {
    this.getArtList();
  },
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
      }
      if(t == null) t = 'dark'
      return t
    },
    lang: function () {
      return this.$i18n.locale
    }
  },
  data() {
    return{
      newsList: [],
      showRQCode1: false,
      showRQCode2: false,
      x_url: "https://x.com/BitJungle_team",
      tiktok_url: "https://www.douyin.com/user/MS4wLjABAAAAjmzdoKmLOD_yxzwFID-IiIHnjI3Nmm5M2bDuzIAkhj8cBl1mPmStgSPmW9fHpnva",
      redbook_url: "https://www.xiaohongshu.com/user/profile/6698756b000000000f03469e"
    }
  },
  methods: {
    showRQCode(index){
      if(index==1)
        this.showRQCode1 = !this.showRQCode1
      else(index==2)
        this.showRQCode2 = !this.showRQCode2
    },
    goCaseApply(){
      this.$router.push("/assist");
    },
    goBlank(url){
      window.open(url)
    },
    showMsg(msg){
      this.$message({
        showClose: false,
        customClass: 'bit-msg bit-msg-'+this.theme,
        message: msg
      });
    },
    router(path){
      if(!path || path==''){
        this.showMsg(this.$t("undoMsg"))
        return
      }
      this.$router.push(path)
    },
    getArtList(){
      newsList().then(res => {
        this.newsList = res.data.data.list
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.dark{
  .home_section_btn_trace {
    background-image: url("../../assets/img/banner-bottom-dark.png");
  }
  .home_section_social {
    background-image: url("../../assets/img/banner-news-dark.png");
  }
  .code-item{
    background-image: url("../../assets/img/banner-qrcode-dark.png");
  }
}
.light{
  .home_section_btn_trace {
    background-image: url("../../assets/img/banner-bottom-light.png");
  }
  .home_section_social {
    background-image: url("../../assets/img/banner-news-light.png");
  }
  .code-item{
    background-image: url("../../assets/img/banner-qrcode-light.png");
  }
}
.jump-img{
  display: none;
}
.home {
  box-sizing: border-box;
  flex: 1;
  .home_section_top {
    height: 480px;
    display: flex;
    justify-content: center;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    .home_section_top_container {
      width: 1200px;
      padding: 120px 0;
      position: relative;
      box-sizing: border-box;
      .col{
        justify-content: center;
      }
    }
    .home_section_top_title {
      height: 64px;
      line-height: 64px;
      font-size: 56px;
      font-weight: 500;
    }

    .home_section_top_btn {
      position: relative;
      margin-top: 80px;
      width: 144px;
      height: 48px;
      line-height: 48px;
      box-sizing: border-box;
      background: linear-gradient(109deg, #1C5BF9 1%, #1C5BF9 11%, #13B9CB 48%, #32E355 85%);
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      .code-item{
        position: absolute;
        top: 60px;
      }
    }

    .home_section_top_subtitle {
      margin-top: 16px;
      font-size: 24px;
      width: 564px;
    }

    .home_section_top_describe {
      font-size: 23px;
      margin-top: 5px;
      width: 530px;
    }

    .home_section_top_img{
      width: 480px;
      height: 340px;
      position: absolute;
      right: 0;
    }
  }

  .home_section_gotrace{
    height: auto !important;
    min-height: 608px !important;
  }
  .home_section_service_flow {
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        gap: 24px !important;
        .home_section_3{
          display: flex;
          align-items: center;
          width: 588px !important;
          height: 180px !important;
          box-sizing: border-box;
          padding-left:40px;
          border-radius: 4px;
          .bit-button{
            width: 94px;
            height: 32px;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 4px;
            z-index: 222;
            letter-spacing: 0px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400 !important;
            font-size: 16px !important;
            border: 1px solid transparent;
            background-clip: padding-box,border-box;
            background-origin: padding-box,border-box;
          }
          .home_section_3_no{
            width: 48px;
            height: 64px;
            line-height: 64px;
            font-weight: 600;
            font-size: 40px;
            letter-spacing: 0px;
            text-align: center;
            margin-right: 24px;

            background: linear-gradient(96.51deg, #1C5BF9 11.24%, #13B9CB 48.33%, #32E355 85.64%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            
          }
          .fc1{
            height: 32px;
            line-height: 32px;
            font-size: 24px;
            margin-bottom: 12px;
          }
          .fc2{
            height: auto !important;
            line-height: 24px;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .home_section_service_content {
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        gap: 24px !important;
        .home_section_sub{
          width: 384px !important;
          min-height: 256px !important;
          height: auto !important;
          padding: 40px !important;
          box-sizing: border-box;
          align-items: center;
          justify-content: flex-start !important;
          img{
            width: 88px;
            height: 88px;
          }
          .fc1{
            font-size: 20px;
            height: 28px;
            line-height: 28px;
            margin-top: 12px;
            margin-bottom: 8px;
          }
          .fc2{
            font-weight: 400;
            font-size: 14px;
            height: auto !important;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }

  .home_section_advantages {
    height: 488px !important;
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        gap: 24px !important;
        .home_section_3{
          display: flex;
          justify-content: center;
          width: 384px !important;
          height: 224px !important;
          box-sizing: border-box;
          padding-left:40px;
          border-radius: 4px;
          img{
            width: 72px;
            height: 72px;
            margin-bottom: 16px;
          }
          .fc1{
            height: 28px;
            line-height: 28px;
            font-size: 20px;
            margin-bottom: 8px;
          }
          .fc2{
            height: auto !important;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .home_section_cooperation {
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        gap: 24px !important;
        .home_section_3{
          width: 588px !important;
          min-height: 188px !important;
          padding: 40px;
          box-sizing: border-box;
          border: none;
          display: flex;
          flex-direction: column;
          justify-content: flex-start !important;
          align-items: flex-start;
          .fc1{
            font-size: 20px;
            height: 28px;
            line-height: 28px;
            margin-bottom: 8px;
          }
          .fc2{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            height: auto !important;
          }
          .home_section_title2{
            width: 100%;
          }
          .home_section_title3{
            min-width: 254px;
            text-align: left;
            margin-top: 16px;
          }
        }
      }
    }
  }

  .home_section_achievements{
    height: 172px;
    background: linear-gradient(96deg, #4C1FD3 2%, #2E62E2 26%, #31C79D 65%, #54EA72 97%);
    color: #FFFFFF;
    .achievements-item{
      gap: 4px;
    }
    .title{
      font-size: 64px;
      height: 64px;
      line-height: 64px;
      font-weight: 600;
      margin-right: 2px;
    }
    .unit{
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 600;
    }
    .subTitle{
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      height: 24px;
    }
  }

  .home_section_case {
    height: 484px !important;
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        flex-wrap: wrap;
        width: 100%;
        gap: 24px !important;
        .home_section_sub{
          width: 384px !important;
          height: 220px !important;
          padding: 32px !important;
          .news_title{
            max-height: 64px;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            text-align: justify;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 限制为两行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
          .news_intro{
            font-size: 16px;
            font-weight: normal;
            line-height: 28px;
            max-height: 84px;
            text-align: justify;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 限制为两行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }
      }
    }
  }

  .home_section_social{
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 390px;
    .home_section_container{
      padding: 88px 0px;
    }
    .home_section_text{
      height: 230px;
      .home_section_title2{
        margin-top: 16px;
        font-weight: 400 !important;
      }
      .row{
        margin-top: 60px;
        gap: 24px;
        position: relative;
        img{
          cursor: pointer;
        }
        .code-item{
          position: absolute;
          right: 100px;
          top: 60px;
        }
      }
    }
  }


}
</style>
